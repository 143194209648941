<template>
  <div class="row">
    <div class="col-md-10">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Create Role</h4>
          <form @submit.prevent="submit">
            <div class="form-group">
              <label>Name</label>
              <input
                type="text"
                placeholder="Name"
                v-model="role.name"
                name="name"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea v-model="role.description" placeholder="Description" class="form-control"></textarea>
            </div>
            <div class="form-group mt-3">
              <label class="mb-3"><strong>Select Permissions</strong></label>
              <div class="row">
                <div class="col-md-4" v-for="(permission, i) in permissions" :key="`permission-${i}`">
                  <div class="form-group">
                    <input type="checkbox" :id="`permission-${i}`" class="mr-1" :value="permission.value" v-model="role.permissions">
                    <label :for="`permission-${i}`">{{ permission.name }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group text-right">
              <button class="btn btn-secondary">
                <i class="lni-save mr-2"></i>Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      role: {
        permissions: []
      },
      permissions: []
    };
  },

  methods: {
    fetch() {
      this.$loader.start()

      this.$axios.get('/api/v1/dashboard/roles/create').then(response => {
        this.permissions = response.data.permissions

        this.$loader.stop()
      })
    },

    submit() {
      this.$loader.start()

      this.$axios.post('/api/v1/dashboard/roles', this.role).then(response => {
        this.$loader.stop()

        this.$router.push({ name: 'roles.show', params: { id: response.data.role_id }})
      })
    }
  },
};
</script>
